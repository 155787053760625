$(function () {
    /**
     * START: Click event listener for #chat-with-us. Used as an in modal
     */
    $(document).on('click', '#chat-with-us', function () {
        if ($("div.LandbotLivechat.is-open").length == 0) {
            if (myLandbotConfigUrl && myLandbotConfigUrl.length > 0) {
                var csLandbot = new Landbot.Livechat({
                    configUrl: myLandbotConfigUrl,
                });
                csLandbot.onLoad(function () {
                    csLandbot.open();
                });
            }
        } 
    });
    /**
     * END: #chat-with-us
     */
});