
// Set the defaults fancybox options
if (typeof $.fancybox !== 'undefined') {
    $.fancybox.defaults.loop = true;
}
/** 
 * ******************************************
 * DOCUMENT LISTENER EVENTS
 * ******************************************
 */

/**
 * START: click of avaspin to toggle "get custom product modal"
 */
$(document).on('click', '#topDiscountCode, #topDiscountCodeMiddle', function (e) {
    $("#getCustomProduct").modal("show");
});

$(document).on('click', '#submitCustomProductPin', function (e) {
    var prodPin = $("#productPin").val();
    $.post('/checkout/getspecialproddetail',
        {
            prodPin: prodPin,
        },
        function (data) {
            var res = data;
            if (res.status == false) {
                $("#prodPinMessage").addClass('text-danger');
            } else if (res.status == true) {
                $("#prodPinMessage").removeClass('text-danger');
                $("#prodPinMessage").addClass('text-success');
            }
            $("#prodPinMessage").text(res.message);
            var productCodes = [];

            if (res.status == true) {
                $("#customProductDialogLaoder").removeClass('d-none');
                $("#submitCustomProductPin").addClass('disabled');

                var prodData = res.data;
                productCodes.push(prodData.product_code);

                $.post('/checkout/addtocheckoutcart', {
                    prodId: prodData.product_id,
                }, function (response) {
                    res = response;
                    if (res.status == false) {
                        $("#prodPinMessage").addClass('text-danger');
                    } else if (res.status == true) {
                        $("#prodPinMessage").removeClass('text-danger');
                        $("#prodPinMessage").addClass('text-success');
                        window.location.href = "/checkout";
                    }
                    $("#prodPinMessage").text(res.message);
                });

                var eventId = new Date().getTime();
                /* Meta Add to Cart event tracking JS */
                fbTrack(
                    'AddToCart',
                    {
                        content_ids: productCodes,
                        content_type: 'product',
                        value: parseFloat(prodData.price).toFixed(2),
                        currency: 'USD'
                    },
                    { eventID: eventId }
                );

                //Meta Pixel Add to Cart Conversion
                $.post('/metapixel/addtocart', {
                    contentIds: productCodes,
                    contentType: 'product',
                    value: parseFloat(prodData.price).toFixed(2),
                    currency: 'USD',
                    eventID: eventId
                }, function (response) {
                    console.log("cart_response " + response);
                });

                var item = {
                    item_id: prodData.product_id,
                    item_name: prodData.product_name,
                    currency: "USD",
                    price: parseFloat(prodData.price).toFixed(2),
                    quantity: 1
                };

                var eventData = { send_to: 'analytics', items: [item] };
                gTagEvent('add_to_cart', eventData);
                gTagEvent('add_to_cart_product', eventData);
            }
        });
});

/**
 * END: toggle "get custom product modal"
 */

/**
 * START: Click event listener for a[rel="facebox"]
 */
$(document).on('click', 'a[rel="facebox"]', function (e) {
    $href = $(this).attr('href');
    $('#facebox-popup-holder').load($href, function () {
        $('#facebox-popup-background').css('height', $(window).height() + 'px').show();
        var $top = 0;
        if ($('#facebox-popup-holder').height() >= $(window).height()) {
            $top = $(window).scrollTop() + 20;
        } else {
            $top = ($(window).height() - $('#facebox-popup-holder').height()) / 2 + $(window).scrollTop();
        }
        $('#facebox-popup-holder').css("top", $top + "px").show();
        $('#facebox-popup-holder').prepend('<a href="#" id="close-popup-link" class="close-facebox-link" title="Close"><img src="/newimg/popup-close.png" alt="Close" width="32" height="32" /></a>');
    });

    e.preventDefault();
    return false;
});
/**
 * END: a[rel="facebox"]
 */

/**
 * START: Click event listener for .close-facebox-link
 */
$(document).on('click', '.close-facebox-link', function (e) {
    $('#facebox-popup-background').hide();
    $('#facebox-popup-holder').hide();
    $('#facebox-popup-holder').html('');
    e.preventDefault();
    return false;
});
/**
 * END: .close-facebox-link
 */

/**
 * START: Click event listener for #close-popup-link
 */
$(document).on('click', '.closePopup', function (e) {
    var target = $(this).attr('data-target');
    if (target) {
        $('#general-popup-wide-background').hide();
        $('#general-popup-wide-holder').hide();
        $('#general-popup-background').hide();
        $('#general-popup-holder').hide();
        $('#' + target).remove();
    }
    e.preventDefault();
    return false;
});
/**
 * END: #close-popup-link
 */

/**
 * START: Click event when the .mobile_phone_number_link element is clicked on.
 */
$(document).on('click', '.mobile_phone_number_link', function (e) {

    // if already in the process of the getting a phone number, do not proceed.
    if (window.requestingNumberForPhone) {
        return;
    }

    // do not proceed, if the link does not have the generate_number_for_phone class
    if (!$(this).hasClass("generate_number_for_phone")) {
        return;
    }

    // indicating request is being processed
    window.requestingNumberForPhone = true;

    // get all phone links
    var allLinks = $(".mobile_phone_number_link");
    var formattedPhoneNumberSpans = $(".formatted_phone_number");

    $.ajax("/ajax/assignphonenumber", {
        method: "POST",
        dataType: "json",
        success: function (data, textStatus, jqXHR) {
            if (data.success) {
                // set the href and remove class "generate_number_for_phone"
                allLinks.each(function () {
                    $(this).attr("href", data.hrefLinkAttr);
                    $(this).removeClass("generate_number_for_phone");
                });

                // add the formatted phone number to each related phone link
                formattedPhoneNumberSpans.each(function () {
                    $(this).text(data.formattedPhone);
                });

                // initiate phone call
                location.href = data.hrefLinkAttr;
            }
            window.requestingNumberForPhone = false;
        },
        error: function (jqXHR, textStatus, errorThrown) {

            window.requestingNumberForPhone = false;

            var errMsg = "AJAX failed. Could not generate a number for a phone device.";
            errMsg += "\nStatus: " + textStatus;
            errMsg += "\nError: " + errorThrown;

            var errObj = new Error(errMsg);

            logError(errObj);
        }
    });
});

/**
 * START: Click event listener for #show-more-feat-products
 */
$(document).on('click', '#show-more-feat-products', function (e) {
    $(this).parent().parent().find('.show-more-link').hide();
    $(this).parent().parent().find('.show-more-loader').show();
    $params = $(this).attr('data-cat').replace('featcount-', '');
    $params = $params.split('-');
    $('#product-load-holder').load('/ajax/reloadfeat/' + $params, function () {
        $('.show-more-box').replaceWith($('#product-load-holder').html());
    });
    e.preventDefault();
    return false;
});
/**
 * END: #show-more-feat-products
 */

/**
 * End: .mobile_phone_number_link
 */
$(function () {
    $('#search').on('keyup', function () {
        $v = $(this).val().toLowerCase();
        if ($v === 'pinz') {
            alert('Your Pin: ' + $('.rkg-phone-pin').find('strong').html());
            setTimeout(function () {
                $('#search').val('');
            }, 20);
        }
    });
    // popups
    $('.popup-800-600').on('click', function (event) {
        var width = 800,
            height = 600,
            left = ($(window).width() - width) / 2,
            top = ($(window).height() - height) / 2,
            url = this.href,
            opts = 'status=1' +
                ',width=' + width +
                ',height=' + height +
                ',top=' + top +
                ',left=' + left +
                ',scrollbars=' + '1';

        window.open(url, 'pop', opts);
        event.preventDefault();
        return false;
    });
    $('.popup-1024-800').on('click', function (event) {
        var width = 1024,
            height = 800,
            left = ($(window).width() - width) / 2,
            top = ($(window).height() - height) / 2,
            url = this.href,
            opts = 'status=1' +
                ',width=' + width +
                ',height=' + height +
                ',top=' + top +
                ',left=' + left +
                ',scrollbars=' + '1';

        window.open(url, 'pop2', opts);
        event.preventDefault();
        return false;
    });
    addEvent(document, 'click', '.infinity-load-more', infinityLoadMore);
});

if (WebPageObj.checkout || WebPageObj.product) {
    $(function () {
        $('#category-head').on('click', function () {
            location.href = $(this).find('a:first-child').attr('href');
        });
    });
}
